import React, { useState, useEffect } from 'react';
import { Routes, Route} from 'react-router-dom';
import { HashLink } from 'react-router-hash-link'
import "./App.css";
import 'animate.css';

// Component
import NavBar from "./components/nav-bar/NavBar";
import Home from "./components/Home";

function App() {

  const [ isVisible, setIsVisible ] = useState(false);

  useEffect(() => {
    const toggleArrowVisibility = () => {
      if(window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleArrowVisibility);

    return () => 
      window.removeEventListener('scroll', toggleArrowVisibility);

  }, [])


  return (
    <div className="">
      <HashLink smooth to="#Top">
        {isVisible && (
          <img
            className="motion-safe:animate-[bounce_3s_infinite] ease-in-out bg-slate-200 drop-shadow-lg rounded-full p-2 right-0 bottom-20 mb-4 mr-3 fixed z-40"
            src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/000000/external-up-arrows-dreamstale-lineal-dreamstale-12.png"
            alt="Top of page"
          />
        )}
      </HashLink>

      <NavBar />
      <Routes>
        <Route path='/' element={ <Home /> } />
      </Routes>
    </div>
  );
}

export default App;
