import React, { lazy } from "react";

// Components
const Header = lazy(() => import("./header/Header"));
const TrainingServices = lazy(() => import("../components/camp-services/training/TrainingServices"));
const CampResources = lazy(() => import('./camp-services/about-me/AboutMe'));
const Footer = lazy(() => import('./footer/Footer'));

export default function Home() {
  return (
    <div className="h-auto antialiased">
      <Header />
      <TrainingServices />
			<CampResources />
      <Footer />
    </div>
  );
}
