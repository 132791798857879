import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Collapse } from "react-collapse";

// Images 
import K from '../../images/K.jpg';

// Components
import NavBarLinks from "./NavBarLinks";

export default function NavBar() {
  const [nav, setNav] = useState(false);

  const navigation = [
    { name: "My Tennis Training", to: "/#TennisCamp" },
    { name: "About KTC", to: "/#AboutKTC" },
    { name: "Contact Me", to: "/#ContactMe" },
  ];

  return (
    <nav id="Top" className="top-0 absolute z-50 w-full antialiased bg-[#FFFAFF] md:bg-transparent">
      {/* Logo Container */}
      <div className={`w-screen flex justify-between 2xl:items-end md:space-x-5 lg:space-x-16 xl:space-x-24 2xl:space-x-48 py-3 px-5 sm:px-14 xl:px-20 `}>
        <HashLink smooth to="/" className=" ">
          <img
            className="w-10 md:w-12 lg:w-16 xl:w-20  md:h-10 lg:h-16 xl:h-20 rounded-full drop-shadow-md"
            src={K} 
            alt="KTC Logo" 
          />
        </HashLink>

        <div className="z-50 flex relative w-8 h-10 flex-col justify-around items-center md:hidden" onClick={() => {
                        setNav(!nav)
                    }}>
                        {/* hamburger button */}
                        <span className={`h-[2px] bg-zinc-500 w-full rounded-lg transform transition duration-300 ease-in-out ${nav ? "rotate-45 translate-y-3.5" : ""}`} />
                        <span className={`h-[2px] bg-zinc-500 w-full rounded-lg transition-all duration-300 ease-in-out ${nav ? "w-0" : "w-full"}`} />
                        <span className={`h-[2px] bg-zinc-500 w-full rounded-lg transform transition duration-300 ease-in-out ${nav ? "-rotate-45 -translate-y-3.5" : ""}`} />
                </div>
        {/* <button onClick={() => setNav(!nav)} className="md:hidden text-gray-700 rounded-lg text-lg">
          <img src="https://img.icons8.com/ios/25/000000/menu-rounded.png" alt="nav drop down" />
        </button> */}

        {/* Larger Screen Navigation */}
        <ul className="hidden md:visible md:flex text-slate-600 mx-5 space-x-1 lg:space-x-3 2xl:pb-9 text-md">
          {navigation.map(({ name, to }, i) => (
            <NavBarLinks key={i} name={name} to={to} />
          ))}
        </ul>
      </div>

      <Collapse isOpened={nav}>
        <ul 
          className="w-full md:hidden py-5 shadow-md  ReactCollapse--collapse relative border-b-2 border-purple-500"
          onClick={() => setNav(!nav)}
        >
          {navigation.map(({ name, to }, i) => (
            <NavBarLinks key={i} name={name} to={to} />
          ))}
        </ul>
      </Collapse>
    </nav>
  );
}
