import React from "react";
import { NavHashLink } from "react-router-hash-link";

export default function NavBarLinks({ name, to }) {
  return (
    <li className="text-right pr-5 p-3 hover:text-slate-400">
      <NavHashLink to={to} smooth className="block sm:text-md lg:text-lg 2xl:text-xl hover:white">
        {name}
      </NavHashLink>
    </li>
  );
}
